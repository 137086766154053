nav {
  display: flex;
  z-index: 1;
  margin: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 30px auto;

    li {
      margin: 15px 0;

      a {
        width: 200px;
        text-align: center;
        overflow: hidden;
        position: relative;
        display: inline-block;
        outline: none;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font: 27px texgyreadventor-regular;

        &:hover, &:focus {
          outline: none;
        }

        span {
          display: block;
          padding: 10px 20px;
          background: #0F0B11;
          transition: transform 0.3s;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          padding: 10px 20px;
          width: 100%;
          height: 100%;
          background: #fff;
          color: #0F0B11;
          content: attr(data-hover);
          transition: transform .3s;
        }

        &:hover span, &:focus span {
          transform: translateX(100%);
        }

        &:hover::before, &:focus::before {
          transform: translateX(0%);
        }

      }
    }
  }

  @media all and (max-width: 630px) {
    font-size: 21px;
    position: relative;

    ul {
      width: 220px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 4%;

      li {
        float: left;
        margin-bottom: 5px;
      }
    }
  }
}
