.grid {
  position: relative;
  margin: 0 auto;
  padding: 1em 0 4em;
  max-width: 1000px;
  text-align: center;
  min-height: 1000px;
  display: block;
  pointer-events: all;

  figure {
    position: relative;
    overflow: hidden;
    margin: 20px auto;
    max-width: 520px;
    max-height: 360px;
    height: 100%;
    text-align: center;
    background: #101710;

    img {
      max-width: 100%;
      transition: opacity 1s, transform 1s;
      display: block;
    }

    figcaption {
      text-align: left;
    }

    h2 {
      position: relative;
      padding: 0.5em 0;
    }

    p {
      text-transform: uppercase;
      margin: 0 0 0.25em;
      padding: 0.4em 1em;
      background: rgba(255, 255, 255, 0.9);
      color: #2f3238;
      font-weight: 500;
      font-size: 75%;
      transition: opacity 0.35s, transform 0.35s;
      transform: translate3d(-360px, 0, 0);
      width: 60%;
      letter-spacing: 1px;

      @media all and (max-width: 590px) and (min-width: 356px) {
        font-size: 58%;
      }

      @media all and (max-width: 355px) {
        font-size: 10px;
      }

      &:first-child {
        transition-delay: 0.25s;
      }

      &:nth-of-type(2) {
        transition-delay: 0.2s;
      }

      &:nth-of-type(3) {
        transition-delay: 0.1s;
      }

      &:nth-of-type(4) {
        transition-delay: 0.05s;
      }

      &:nth-of-type(5) {
        transition-delay: 0.02s;
      }
    }

    &:hover, &:focus {
      p {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        &:first-child {
          transition-delay: 0s;
        }

        &:nth-of-type(2) {
          transition-delay: 0.05s;
        }

        &:nth-of-type(3) {
          transition-delay: 0.1s;
        }
      }

      img {
        opacity: 0.4;
        transform: scale3d(1.1, 1.1, 1);
      }
    }


    @media all and (max-width: 355px) {
      height: 130px;
      margin: 30px 0;
    }

    &:hover, &:focus {
      a {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        text-transform: uppercase;
        margin: 0;
        padding: 0 15px;
        background: rgba(255, 255, 255, 0.9);
        color: black;
        text-align: center;
        height: 100%;

        span {
          position: relative;
          top: 50%;
          margin: auto;
        }
      }
    }

    a {
      display: none;
    }

    img {
      position: relative;
      display: block;
      min-height: 100%;
      max-width: 100%;

      @media all and (max-width: 624px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    figcaption {
      padding: 1.5em;
      color: #fff;
      text-transform: uppercase;
      font-size: 0.90em;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;

      > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
      }
    }

    h2 {
      word-spacing: -0.15em;
      font-weight: 300;

      span {
        font-weight: 800;
      }

      margin: 0;
    }
  }
}


